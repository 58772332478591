import React, {useRef} from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { FaBars, FaMapPin, FaPhoneAlt } from 'react-icons/fa'
import anbLogo from '../images/anb-logo.png'

const Navbar = ({navPosition, navScrolled}) => {
  const menu = useRef(null)

  const toggleMenu = () => {
    menu.current.classList.toggle('show')
  }

  return (
    <>
      <NavContainer navPosition={navPosition} className={`${navScrolled && 'scrolled'}`} transitionTime='0.4s' >
        <div className="bars"><FaBars onClick={toggleMenu} /></div>
        <div><Link to="/"><img src={anbLogo} alt="Adventure and Beyond Logo" className="logo"/></Link></div>
        <div className="details"><a href="https://www.google.com/maps/dir/?api=1&destination=Adventure+n+Beyond" target="_blank" rel="noreferrer"><FaMapPin />&nbsp; Muldersdrift</a>&nbsp; | &nbsp;<a href="tel:0878057910"><FaPhoneAlt />&nbsp; 087 805 7910</a></div>
      </NavContainer>
      <NavLinks ref={menu} onClick={toggleMenu}>
        <li><Link to='/'>Home</Link></li>
        {/* <li><Link to='/bookings'>Bookings</Link></li> */}
        <li><Link to='/#gallery'>Gallery</Link></li>
        <li><Link to='/#pricing'>Pricing</Link></li>
        <li><Link to='/#testimonials'>Reviews</Link></li>
        <li><Link to='/about'>About</Link></li>
        <li><Link to='/contact'>Contact</Link></li>
      </NavLinks>
    </>
  )
}

Navbar.defaultProps = {
  navPosition: 'sticky',
  navScrolled: true
}

// --- STYLES ---
const NavContainer = styled.nav`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: ${props => props.navPosition};
  top: 0;
  width: 100%;
  height: 4.5rem;
  padding: 0 2rem;
  color: #fff;
  z-index: 100;
  transition: background ${props => props.transitionTime};

  &.scrolled {
    background: rgba(30,30,30,0.9);
    border-bottom: solid 2px var(--primary-color);

    .logo {
      transform: none;
    }
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bars {
    justify-content: start;
    font-size: 2rem;
    z-index: 101;
    cursor: pointer;
  }

  .logo {
    height: 4rem;
    object-fit: contain;
    transform: translateY(30px) scale(1.8);
    transition: transform ${props => props.transitionTime};
    transition-timing-function: cubic-bezier(0.390, 0.265, 0.575, 1.650);
  }

  .details { 
    justify-content: flex-end;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  @media(max-width: 480px) {
    padding: 0 1rem;

    .logo {
      transform: initial;
    }

    .details {
      display: none;
    }
  }
`

const NavLinks = styled.ul`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--primary-color);
  color: #fff;
  width: 80vw;
  height: 100vh;
  max-width: 30rem;
  padding-top: 6rem;
  font-size: 1.8rem;
  list-style: none;
  z-index: 99;
  transform: translateX(-100%);
  transition: transform 0.4s;

  li {
    border-bottom: solid 1px #555;
    padding: 1.6rem;
  }

  &.show {
    transform: translateX(0);
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    font-size: 1.2rem;

    li {
      padding: 1rem;
    }
  }
`

export default Navbar
