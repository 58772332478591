import React from 'react'
import styled from 'styled-components'
import { FaMapPin, FaRegClock, FaPhoneAlt, FaEnvelope, FaFacebook } from 'react-icons/fa'

const Footer = () => {
  return (
    <FooterContainer>
      <div id="info">
        <div>
          <p><FaMapPin />&nbsp; <a href="https://www.google.com/maps/dir/?api=1&destination=Adventure+n+Beyond" target="_blank" rel="noreferrer">Adventure 'n' Beyond, 7 Henry Road, Northvale AH, Krugersdorp</a></p>
          <p><FaRegClock />&nbsp; Tuesday - Sunday: 9:00 - 17:00 | Monday: Closed</p>
          <p><FaPhoneAlt />&nbsp; <a href="tel:0878057910">087 805 7910</a> | <FaEnvelope />&nbsp; <a href="mailto:bookings@anb.co.za">bookings@anb.co.za</a></p>
          <br/>
          <a title='Facebook' href="https://www.facebook.com/AdventureNBeyond" target="_blank" rel="noreferrer"><FaFacebook id='facebook'>Facebook</FaFacebook></a>
          <br/><br/>
          <p>Adventure 'n' Beyond NPC &copy; 2020 | Website Developed by <a href="https://itgenie.co.za" target="_blank" rel="noreferrer">IT Genie Pty Ltd</a></p>
        </div>
      </div>
      <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3584.4138138656067!2d27.825783315184648!3d-26.05272916415974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e959bf44a70d635%3A0x815100c064c9a551!2sAdventure%20n%20Beyond!5e0!3m2!1sen!2sza!4v1604929138416!5m2!1sen!2sza" 
        // width="600" 
        height="450" 
        frameBorder="0" 
        style={{border:0}}
        allowFullScreen="" 
        aria-hidden="false" 
        // tabIndex="0"
        id="map"
        title="Footer Map"></iframe>
    </FooterContainer>
  )
}

/* =========
   STYLES
   ========= */

const FooterContainer = styled.footer`
  position: relative;
  display: flex;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 0.9rem;

  a  {
    color: inherit;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--primary-color);
    z-index: -1;
  }

  #info {
    flex: 1.5;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 2;

    #facebook { font-size: 2rem; }
  }

  #map {
    flex: 1;
  }

  @media (max-width: 576px) {
    display: block;

    #info {
      display: block;
      padding: 4rem 1rem;
    }

    #map {
      width: 100%;
      height: 16rem;
    }
  }
`

export default Footer