import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import WhatsappContactIcon from './WhatsappContactIcon'

const Layout = ({ children, ...restProps }) => {
  return (
    <>
      <Navbar {...restProps} />
        {children}
        <WhatsappContactIcon number='27798936838' />
      <Footer/>
    </>
  )
}

export default Layout
