import React from 'react'
import styled from 'styled-components'
import { FaWhatsapp } from 'react-icons/fa'

const WhatsappIcon = styled.a`
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #25D366;
    color: #fff;
    width: 4.5rem;
    height: 4.5rem;
    margin: 1.5rem;
    border-radius: 50%;
    z-index: 99;
`

const WhatsappContactIcon = ({number}) => {
    return (
        <WhatsappIcon title='Whatsapp' href={`https://api.whatsapp.com/send?phone=${number}`} target='_blank' rel='noreferrer' >
            <FaWhatsapp style={{ fontSize: '3rem' }}>Whatsapp</FaWhatsapp>
        </WhatsappIcon>
    )
}

export default WhatsappContactIcon
